import { getInitialTimeSliderHours } from '@/lib/timeSliderFilter'
import { FacetCount } from '@/shared/api-generated-types'
import dayjs from 'dayjs'
import React, { useContext, useReducer } from 'react'
type Actions =
  | 'APPLY_SELECTED_SCHEDULE_FILTERS'
  | 'FETCH_SCHEDULE_FROM_API'
  | 'GOTO_CURRENT_DATE'
  | 'RESET_ACTIVE_SCHEDULE_FILTERS'
  | 'SET_ACTIVE_SCHEDULE_DATE'
  | 'SET_ACTIVE_SCHEDULE_FILTERS'
  | 'SET_CALENDAR_DATE'
  | 'SET_IS_FOOTER_VISIBLE'
  | 'SET_REFRESH_SCHEDULE_FILTERS'
  | 'SET_SCHEDULE_FILTERS'
  | 'SET_SCHEDULE_TIME_FILTERS'

type ProviderProps = { children: React.ReactNode }

export interface SchedulePageState {
  day: string | Date
  dayString: string
  facets: string[]
  index: number
  reset: boolean
  sessionsIntervalStart: number
  sessionsIntervalEnd: number
}

interface ScheduleFiltersState {
  categories: FacetCount[]
  guides: FacetCount[]
}
export interface ScheduleTimeFiltersState {
  sessionsIntervalStart: number
  sessionsIntervalEnd: number
}
interface PageFooterState {
  isVisible: boolean
}

type State = {
  calendarSelectedDay: Date
  pageFooter: PageFooterState
  scheduleFilters: ScheduleFiltersState
  schedulePage: SchedulePageState
}

interface Action<T extends keyof State> {
  type: Actions
  payload?: State[T]
}
type Dispatch = (action: Action<keyof State>) => void

interface ContextInterface {
  state: State
  dispatch: Dispatch
}

const Context = React.createContext<ContextInterface | undefined>(undefined)

const { start: initialSessionsIntervalStart, end: initialSessionsIntervalEnd } = getInitialTimeSliderHours()

const initialState: State = {
  calendarSelectedDay: dayjs(new Date()).toDate(),
  schedulePage: {
    day: dayjs(new Date()).format(),
    dayString: new Date().toISOString(),
    facets: [],
    index: 0,
    reset: false,
    sessionsIntervalStart: initialSessionsIntervalStart,
    sessionsIntervalEnd: initialSessionsIntervalEnd,
  },
  scheduleFilters: {
    categories: [],
    guides: [],
  },
  pageFooter: {
    isVisible: false,
  },
}

const reducer = (state: State, action: Action<keyof State>): Partial<State> => {
  switch (action.type) {
    case 'APPLY_SELECTED_SCHEDULE_FILTERS': {
      return {
        ...state,
        schedulePage: action.payload as SchedulePageState,
      }
    }
    case 'SET_ACTIVE_SCHEDULE_DATE': {
      return {
        ...state,
        schedulePage: {
          ...(action.payload as SchedulePageState),
          reset: false,
        },
      }
    }
    case 'SET_ACTIVE_SCHEDULE_FILTERS': {
      return {
        ...state,
        schedulePage: {
          ...(action.payload as SchedulePageState),
          day: initialState.schedulePage.day,
          dayString: initialState.schedulePage.dayString,
          reset: false,
        },
      }
    }
    case 'RESET_ACTIVE_SCHEDULE_FILTERS': {
      return {
        ...state,
        calendarSelectedDay: initialState.calendarSelectedDay,
        schedulePage: {
          ...initialState.schedulePage,
          reset: false,
        },
      }
    }
    case 'GOTO_CURRENT_DATE': {
      return {
        ...state,
        schedulePage: {
          day: initialState.schedulePage.day,
          dayString: initialState.schedulePage.dayString,
          facets: [],
          index: initialState.schedulePage.index,
          sessionsIntervalStart: initialState.schedulePage.sessionsIntervalStart,
          sessionsIntervalEnd: initialState.schedulePage.sessionsIntervalEnd,
          reset: false,
        },
      }
    }
    case 'SET_SCHEDULE_FILTERS': {
      return {
        ...state,
        scheduleFilters: action.payload as ScheduleFiltersState,
      }
    }
    case 'SET_CALENDAR_DATE': {
      return {
        ...state,
        calendarSelectedDay: action.payload as Date,
      }
    }
    case 'SET_IS_FOOTER_VISIBLE': {
      return {
        ...state,
        pageFooter: action.payload as PageFooterState,
      }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

export const Provider = ({ children }: ProviderProps): JSX.Element => {
  // @ts-ignore
  const [state, dispatch] = useReducer(reducer, initialState)
  const value = { state, dispatch }
  return <Context.Provider value={value}>{children}</Context.Provider>
}
const UIProvider = Provider

function useUI(): ContextInterface {
  const context = useContext(Context)
  if (context === undefined) {
    throw new Error('useUI must be used within a Provider')
  }
  return context
}

export { UIProvider, useUI }
