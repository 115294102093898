import { useAuth } from '@/context/auth'
import { createReminderSubscription, deleteReminderSubscription } from '@/lib/reminders'
import type {
  ArticleCard,
  ClubCard,
  EnrichedClassV3SearchResult,
  MyReminderSubscriptionsOutput,
  ReminderCard,
} from '@/shared/api-generated-types'
import { useRouter } from 'next/router'
import * as React from 'react'
import { gql, useQuery } from 'urql'

export const QUERY_MY_REMINDER_SUBSCRIPTIONS = gql`
  query myReminderSubscriptions {
    myReminderSubscriptions {
      subscriptions {
        reminderId
      }
    }
  }
`

export enum MessageType {
  UserFreeText = 'user_free_text',
  UserStaticPrompt = 'user_static_prompt',
  UserDynamicPrompt = 'user_dynamic_prompt',
}

type Dispatch = (action: Action) => void
type ProviderProps = { children: React.ReactNode }
type Action = { type: 'UPDATE'; payload: Partial<State> }
type State = {
  query: string
  messageType: MessageType
  showPrompts: boolean
  showRemindersEmailPopup: boolean
  relatedArticles: ArticleCard[]
  relatedClasses: EnrichedClassV3SearchResult[]
  relatedClubs: ClubCard[]
  relatedLiveClasses: EnrichedClassV3SearchResult[]
  relatedReminders: ReminderCard[]
  subscribedReminders: string[]
}
interface ContextInterface {
  state: State
  dispatch: Dispatch
  subscribeReminder: (reminderId: string) => Promise<void>
  unsubscribeReminder: (reminderId: string) => Promise<void>
}

const Context = React.createContext<ContextInterface | undefined>(undefined)

const initialState: State = {
  query: '',
  messageType: MessageType.UserFreeText,
  showPrompts: true,
  showRemindersEmailPopup: false,
  relatedArticles: [],
  relatedClasses: [],
  relatedClubs: [],
  relatedLiveClasses: [],
  relatedReminders: [],
  subscribedReminders: [],
}

function reducer(state: State, action: Action): State {
  switch (action.type) {
    case 'UPDATE': {
      return { ...state, ...action.payload }
    }
    default: {
      return {
        ...state,
      }
    }
  }
}

function Provider({ children }: ProviderProps): JSX.Element {
  const authProvider = useAuth()
  const router = useRouter()
  const searchQuery = router.query.q as string | undefined
  const [state, dispatch] = React.useReducer(reducer, { ...initialState, query: searchQuery || '' })

  const [{ data, fetching }] = useQuery<{
    myReminderSubscriptions: MyReminderSubscriptionsOutput
  }>({
    query: QUERY_MY_REMINDER_SUBSCRIPTIONS,
    pause: authProvider.state.user === null,
  })

  const subscribeReminder = React.useCallback(
    async (reminderId: string): Promise<void> => {
      const results = await createReminderSubscription({ reminderId })
      if (results) {
        dispatch({ type: 'UPDATE', payload: { subscribedReminders: [...state.subscribedReminders, reminderId] } })
      }
    },
    [state.subscribedReminders],
  )

  const unsubscribeReminder = React.useCallback(
    async (reminderId: string): Promise<void> => {
      const results = await deleteReminderSubscription({ reminderId })
      if (results) {
        const index = state.subscribedReminders.indexOf(reminderId)
        if (index > -1) {
          state.subscribedReminders.splice(index, 1)
        }
        dispatch({ type: 'UPDATE', payload: { subscribedReminders: state.subscribedReminders } })
      }
    },
    [state.subscribedReminders],
  )

  React.useEffect(() => {
    if (
      fetching ||
      !data?.myReminderSubscriptions ||
      !data?.myReminderSubscriptions.subscriptions ||
      data?.myReminderSubscriptions.subscriptions.length === 0
    )
      return
    const subscribedReminders = data.myReminderSubscriptions.subscriptions.map(
      (subscription) => subscription.reminderId,
    )
    dispatch({ type: 'UPDATE', payload: { subscribedReminders } })
  }, [data, fetching])

  return (
    <Context.Provider value={{ state, dispatch, subscribeReminder, unsubscribeReminder }}>{children}</Context.Provider>
  )
}
const AiProvider = Provider

function useAi(): ContextInterface {
  const context = React.useContext(Context)
  if (context === undefined) {
    throw new Error('useAi must be used within a Provider')
  }
  return context
}
export { AiProvider, useAi }
