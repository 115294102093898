export const root = "r5viggb";
export const stack = "s1dyb5uy";
export const stackX = "s1pkeyy";
export const stackY = "s1w1vccb";

// Layout spacing modifiers, which cascade to the Stack components
// Half sizes are used to allow overlapping margins to stack without
// compromising the integrity of the design system, whilst also enable
// flexibility of what direction the stack may be going based on 'align' prop
export const spacing_sm = "s6sp9uf";
export const spacing_md = "s57par7";
export const spacing_lg = "s164z99z";
export const space_between_x_sm = "s190egtk";
export const space_between_x_md = "s11442g9";
export const space_between_x_lg = "s1j7od8w";
export const space_between_y_xs = "s149jnyc";
export const space_between_y_sm = "s1pguym5";
export const space_between_y_md = "s3l0fqb";
export const space_between_y_lg = "s1bvni27";

require("./style.linaria.module.css!=!../../../node_modules/@linaria/webpack-loader/node_modules/@linaria/webpack5-loader/lib/outputCssLoader.js?cacheProvider=!./style.ts");