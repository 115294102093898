import Icon, { ValidIcon } from '@/components/Icon'
import Text, { Sizes } from '@/components/Text'
import { UnitSize } from '@/constants/measured-scope'
import classNames from 'classnames/bind'
import Link from 'next/link'
import React from 'react'
import * as styles from './style'
const cx = classNames.bind(styles)

type ButtonType = 'button' | 'submit' | 'link' | 'a'
export type ButtonTheme =
  | 'primary'
  | 'secondary'
  | 'primaryMuted'
  | 'facebookConnect'
  | 'tertiary'
  | 'clear'
  | 'glass'
  | 'white'
  | 'smoke'
  | 'green'
  | 'primaryOutline'
  | 'primaryOutlineHover'
  | 'secondaryOutline'
  | 'whiteOutline'
  | 'accent'
  | 'link'
  | 'noBackground'
  | 'accentLink'

export interface Props {
  onClick?: ((event: React.MouseEvent) => void) | (() => void) | ((...args: any[]) => void)
  onClickDisabled?: ((event: React.MouseEvent) => void) | (() => void) | ((...args: any[]) => void)
  label?: React.ReactNode | React.ReactNode[]
  component?: ButtonType
  size?: UnitSize | 'xl' | 'xs'
  theme?: ButtonTheme
  className?: string
  href?: string
  target?: string
  disabled?: boolean
  loading?: boolean
  stretch?: boolean
  iconLeft?: ValidIcon
  iconRight?: ValidIcon
  iconSize?: number
  title?: string
  tabIndex?: number
  sharpCorners?: boolean
  textOnly?: boolean
  fontSize?: Sizes
  form?: string
}

export default React.forwardRef(function Button(
  {
    label,
    component = 'button',
    size = 'md',
    theme = 'primary',
    className,
    href,
    target,
    onClick,
    onClickDisabled,
    disabled,
    loading,
    iconLeft,
    iconRight,
    iconSize,
    stretch,
    title,
    tabIndex,
    sharpCorners = false,
    textOnly = false,
    fontSize = 'md',
    form,
  }: Props,
  ref: React.Ref<any>,
): JSX.Element {
  const classes = cx({
    root: true,
    [styles[`${size}`]]: true,
    [styles[`${theme}`]]: true,
    [`${className}`]: true,
    textOnly,
    disabled: disabled || loading,
    notDisabled: !disabled,
    stretch,
    sharpCorners,
    'gsu-btn': true,
    iconOnly: (!!iconLeft && !label && !iconRight) || (!!iconRight && !label && !iconLeft),
  })

  const handleClick = (event: React.MouseEvent): void => {
    if (component !== 'link' && component !== 'submit' && component !== 'a') event.preventDefault()
    if (onClick && !disabled) {
      onClick(event)
    }
    if (onClickDisabled && disabled) {
      onClickDisabled(event)
    }
    return
  }

  const sz = iconSize ? iconSize : size === 'lg' || size === 'xl' ? 32 : 18

  const labelsIcons = (
    <>
      {!loading && iconLeft ? <Icon className="mr-xxs" size={sz} name={iconLeft} /> : null}
      {loading && <img src="/icons/spinner.svg" alt="loading" className="px-lg" loading="lazy" />}
      {!loading && label && <span>{label}</span>}
      {!loading && iconRight ? <Icon className="ml-xxs" size={sz} name={iconRight} /> : null}
    </>
  )

  if (component === 'a') {
    return (
      <a
        href={disabled || loading || !href ? '' : href}
        data-testid="button-link"
        className={classes}
        target={target || undefined}
        title={title}
        ref={ref}
        tabIndex={tabIndex}
        onClick={handleClick}
      >
        {labelsIcons}
      </a>
    )
  } else if (component === 'link') {
    return (
      <Link href={disabled || loading || !href ? '' : href} legacyBehavior passHref>
        <a
          data-testid="button-link"
          className={classes}
          target={target || undefined}
          title={title}
          ref={ref}
          tabIndex={tabIndex}
          onClick={handleClick}
        >
          {labelsIcons}
        </a>
      </Link>
    )
  } else if (component === 'submit') {
    return (
      <input
        className={classes}
        type="submit"
        data-testid="button-submit"
        onClick={handleClick}
        value={label && typeof label === 'string' ? label : 'Submit'}
        title={title}
        tabIndex={tabIndex}
        ref={ref}
        form={form}
      />
    )
  } else {
    return (
      <button
        data-testid="button-button"
        className={classes}
        onClick={handleClick}
        title={title}
        ref={ref}
        tabIndex={tabIndex}
      >
        {textOnly ? <Text size={fontSize}>{labelsIcons}</Text> : labelsIcons}
      </button>
    )
  }
})
