import { encode, ParsedUrlQuery } from 'querystring'
import { SupportedLocale, supportedLocales } from '@/locales/supportedLocales'

export function isValidLocale(locale: unknown): locale is SupportedLocale {
  return typeof locale === 'string' && Boolean(supportedLocales.find((val) => val === locale))
}

export function LocaleToDisplay(locale?: string): string {
  // Check the locale is supported for the environment, if not return English
  if (!isValidLocale(locale)) {
    return 'En'
  }

  switch (locale) {
    case 'en-US':
      return 'En'
    // case 'hi-IN':
    //   return 'हिंदी'
    // case 'zh-CN':
    //   return '普通話'
    // case 'es-US':
    //   return 'Es'
    default:
      return 'En'
  }
}

export function storeLocaleCookie(locale: SupportedLocale): void {
  const currentDomain = window.location.hostname
  const cookieDomain = currentDomain === 'localhost' ? 'localhost' : `.${currentDomain}` // localhost wildcard cookie doesn't work.
  document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; domain=${cookieDomain}; path=/`
}

// It is preferable to make a full page reload when changing the locale, rather than a client side navigation,
// due to the `NEXT_LOCALE` cookie being missing from subsequent requests since it's essentially a SPA.
export function RedirectToLocale(pathname: string, query: ParsedUrlQuery, locale: SupportedLocale): void {
  const url = new URL(locale !== 'en-US' ? `${locale}/${pathname}` : pathname, window.location.origin)
  const urlQueryString = encode(query)
  url.search = urlQueryString
  window.location.href = url.toString()
}
