import { getConfig } from '@/lib/config'
import type {
  ReminderSubscriptionResult,
  SetUserValidEmailInput,
  SetUserValidEmailOutput,
} from '@/shared/api-generated-types'
import * as Sentry from '@sentry/nextjs'

const {
  publicRuntimeConfig: {
    graphqlApi: { url },
  },
} = getConfig()

export interface Props {
  reminderId: string
}

export async function createReminderSubscription({ reminderId }: Props): Promise<boolean> {
  if (!reminderId) return false
  try {
    const mutationBody = {
      query: `
        mutation CreateReminderSubscription($input: CreateReminderSubscriptionInput!) {
          createReminderSubscription(input: $input) { success }
        }
        `,
      variables: {
        input: {
          reminderId,
        },
      },
    }
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mutationBody),
    })
    const parsedResponse = (await response.json()) as {
      data: { createReminderSubscription: ReminderSubscriptionResult }
    }
    return parsedResponse.data.createReminderSubscription.success
  } catch (err) {
    // Log this error to sentry but dont panic.
    Sentry.captureException(err)
    return false
  }
}

export async function deleteReminderSubscription({ reminderId }: Props): Promise<boolean> {
  if (!reminderId) return false
  try {
    const mutationBody = {
      query: `
        mutation DeleteReminderSubscription($input: DeleteReminderSubscriptionInput!) {
          deleteReminderSubscription(input: $input) { success }
        }
        `,
      variables: {
        input: {
          reminderId,
        },
      },
    }
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mutationBody),
    })
    const parsedResponse = (await response.json()) as {
      data: { deleteReminderSubscription: ReminderSubscriptionResult }
    }
    return parsedResponse.data.deleteReminderSubscription.success
  } catch (err) {
    // Log this error to sentry but dont panic.
    Sentry.captureException(err)
    return false
  }
}

export async function setUseValidEmail({
  email,
}: SetUserValidEmailInput): Promise<Pick<SetUserValidEmailOutput, 'message' | 'success'>> {
  if (!email) return { message: 'MISSING_INPUT', success: false }
  try {
    const mutationBody = {
      query: `
        mutation setUserValidEmail($input: SetUserValidEmailInput!) {
          setUserValidEmail(input: $input) { success message }
        }
        `,
      variables: {
        input: {
          email,
        },
      },
    }
    const response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(mutationBody),
    })
    const parsedResponse = (await response.json()) as {
      data: { setUserValidEmail: SetUserValidEmailOutput }
    }
    return parsedResponse.data.setUserValidEmail
  } catch (err) {
    // Log this error to sentry but dont panic.
    Sentry.captureException(err)
    return { message: 'UNEXPECTED_ERROR', success: false }
  }
}
