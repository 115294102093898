import { getConfig } from '@/lib/config'
import { googleTagManager } from '@/lib/googleTagManager'
import { firstStringVal } from '@/lib/queryString'
import {
  AIElementName,
  AppName,
  EventProducer,
  EventProducerOptions,
  GeneratingPage,
  UIElementName,
  WebPlatform,
} from '@getsetup-io/event-producer'
import {
  AppStartEvent,
  BookingCancellationEvent,
  BookingEvent,
  PageViewEvent,
  SearchEvent,
} from '@getsetup-io/event-producer/dist/schema/types'
import { Router } from 'next/router'

// ReExport types
export { GeneratingPage, UIElementName as UIElementNames, AIElementName as AIElementNames }

interface ClickCallback {
  (): void
}

// Config
const {
  publicRuntimeConfig: {
    environment,
    events: { cookieDomain },
  },
} = getConfig()
const env = environment === 'production' ? 'production' : 'development'
const webPlatform = WebPlatform({ cookieDomain })

// Events Tracker
class EventsTracker {
  private eventProducer?: EventProducer
  private isEmbedded: boolean = false

  constructor(webPlatform: WebPlatform, env: 'development' | 'production') {
    if (typeof window !== 'undefined') {
      this.eventProducer = EventProducer(webPlatform, env)
      this.init()
    }
  }

  // Since we know the query params on load, let's set what we can of the
  // embedded options and update later with the external token if we get one.
  // See: useExternalToken.ts hook
  private setMaybeInitialEmbeddedOptions(): void {
    const params = new URLSearchParams(window.location.search)

    const embeddedOrgIdFromQueryParam = firstStringVal(params.getAll('embedding-org-id'))
    const embeddedDeviceIdFromQueryParam = firstStringVal(params.getAll('device-id'))
    const embeddedAnalyticsInfoFromQueryParam = firstStringVal(params.getAll('analytics-info'))

    let embeddedAnalyticsInfo: Record<string, string> | undefined
    if (embeddedAnalyticsInfoFromQueryParam) {
      try {
        embeddedAnalyticsInfo = JSON.parse(atob(decodeURIComponent(embeddedAnalyticsInfoFromQueryParam)))
      } catch (error) {
        // Log the error so devs can see what is going on, but don't break the page.
        console.error("Decoding the analytics info didn't work for some reason")
        console.error(error)
      }
    }

    this.isEmbedded = !!embeddedOrgIdFromQueryParam

    if (embeddedOrgIdFromQueryParam) {
      this.configureOptions({
        embeddedOptions: {
          identifier: embeddedOrgIdFromQueryParam,
          deviceIdOverride: embeddedDeviceIdFromQueryParam,
          embeddedDomain: embeddedAnalyticsInfo?.domain,
        },
      })
    }
  }

  private async init(): Promise<void> {
    try {
      this.setMaybeInitialEmbeddedOptions()

      if (!this.isEmbedded) {
        // Only init Google Tag Manager and FaceBook Pixel if we are not on the embedded site.
        // We don't want third party tracking for embedded users.
        googleTagManager.initSDK()
      }

      // Send AppStart Event
      this.appStartEvent({
        appName: AppName.WebNext,
        appVersion: environment,
      })

      // Send PageView Event
      this.pageViewEvent({ pageUrl: window.location.pathname })

      // Start PageView Observer
      Router.events.on('routeChangeComplete', (pageUrl) => {
        this.pageViewEvent({ pageUrl })
      })
    } catch (error) {
      console.error('sendAppStartEvent', error)
    }
  }

  appStartEvent(event: Pick<AppStartEvent, 'appName' | 'appVersion'>): void {
    this.eventProducer?.appStartEvent(event)
  }

  bookingEvent(event: Pick<BookingEvent, 'classId' | 'slotId'>): void {
    this.eventProducer?.bookingEvent({ classId: event.classId, slotId: event.slotId })
  }

  bookingCancellationEvent(event: Pick<BookingCancellationEvent, 'classId' | 'sessionId'>): void {
    this.eventProducer?.bookingCancellationEvent(event)
  }

  handleOnClickAndTrackEvent(id: AIElementName | UIElementName, ...callbacks: ClickCallback[]): void {
    this.eventProducer?.clickEvent({ uiElementName: id })
    for (const callback of callbacks) {
      callback()
    }
  }

  handleOnClickWithDistinctionAndTrackEvent(
    id: AIElementName | UIElementName,
    distinction: string | null | undefined,
    ...callbacks: ClickCallback[]
  ): void {
    const uiElementDistinction = distinction ?? undefined
    this.eventProducer?.clickEvent({ uiElementName: id, uiElementDistinction })
    for (const callback of callbacks) {
      callback()
    }
  }

  handleOnClickWithDistinctionOptionsAndTrackEvent(
    distinction: string | null | undefined,
    id?: AIElementName | UIElementName,
    classId?: string,
    sessionId?: string,
    ...callbacks: ClickCallback[]
  ): void {
    const uiElementDistinction = distinction ?? undefined
    this.eventProducer?.clickEvent({ uiElementDistinction, uiElementName: id, sessionId, classId })
    for (const callback of callbacks) {
      callback()
    }
  }

  handleSearchOnClick(
    positionIndex: number,
    generatingPage: GeneratingPage,
    pageUrl?: string,
    ...callbacks: ClickCallback[]
  ): void {
    if (!pageUrl) pageUrl = window.location.pathname
    this.eventProducer?.clickEvent({ pageUrl: pageUrl, positionIndex: positionIndex, generatingPage: generatingPage })
    for (const callback of callbacks) {
      callback()
    }
  }

  pageViewEvent(event: Pick<PageViewEvent, 'pageUrl'>): void {
    this.eventProducer?.pageViewEvent(event)
  }

  async searchEvent(keyword: string): Promise<void> {
    if (!keyword) return
    const event: Pick<SearchEvent, 'keyword'> = { keyword }
    this.eventProducer?.searchEvent(event)
  }

  configureOptions(options: EventProducerOptions): void {
    this.eventProducer?.configureOptions(options)
  }
}

export const eventsTracker = new EventsTracker(webPlatform, env)
